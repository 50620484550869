export const ligthTheme = {
    textDark: "hsl(239, 47%, 31%)",
    textDesaturated: "hsla(239, 47%, 31%, 15%)",
    textDark1: "hsl(240, 21%, 65%)",
    bgBlueLight: "hsl(239, 100%, 86%)",
    textWhite: "hsl(0, 0%, 100%)",
    bgBlueDark: "hsl(239, 47%, 31%)",
    bgGreyLighter: "hsl(240, 22%, 96%)",
    bgWhite: "hsl(0, 0%, 100%)",
    textred: "hsl(0, 78%, 63%)",
} as const;
export const darkTheme = {
    textDark: "hsl(239, 47%, 71%)",
    textDesaturated: "hsla(239, 47%, 71%, 15%)",
    textDark1: "hsl(240, 21%, 65%)",
    bgBlueLight: "hsl(239, 60%, 86%)",
    textWhite: "hsl(0, 0%, 00%)",
    bgBlueDark: "hsl(239, 47%, 71%)",
    bgGreyLighter: "hsl(240, 22%, 10%)",
    bgWhite: "hsl(0, 0%, 0%)",
    textred: "hsl(0, 58%, 63%)",
} as const;