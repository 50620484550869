import "./App.css";
import { ThemeProvider } from "styled-components";
import { Route, Switch } from "react-router-dom";
import Home from "./Screens/Home";
import Contact from "./Screens/Contact";
import Projects from "./Screens/Projects";
import Navigation from "./Components/Layout/Navigation";
import "animate.css/animate.min.css";
import Footer from "./Components/Layout/Footer";
import { useState } from "react";
import { useEffect } from "react";
import { ligthTheme, darkTheme } from "./global/theme";
import NotFound from "./Screens/NotFound";
import useTracker from "./Hooks/useTracker";
function App() {
  const [theme, setTheme] = useState<"light" | "dark" | string>("light");
  useTracker();
  useEffect(() => {
    const startTheme = window.localStorage.getItem("theme") || "light";
    setTheme(startTheme);
  }, []);
  useEffect(() => {
    document.body.style.backgroundColor =
      theme === "light" ? ligthTheme.bgWhite : darkTheme.bgWhite;
  }, [theme]);
  const themeToggler = () => {
    if (theme === "light") {
      setTheme("dark");
      window.localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      window.localStorage.setItem("theme", "light");
    }

    console.log(window.localStorage.getItem("theme"));
  };
  return (
    <ThemeProvider theme={theme === "light" ? ligthTheme : darkTheme}>
      <Navigation theme={theme} themeToggler={themeToggler} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/contact/" component={Contact} />
        <Route path="/projects/" component={Projects} />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
    </ThemeProvider>
  );
}
export default App;
