import { Link } from "react-router-dom";
import styled from "styled-components";

export const Button = styled(Link)`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  display: inline-block;
  text-decoration: none;
  background-color: ${({ theme }) => theme.bgBlueDark};
  color: ${({ theme }) => theme.textWhite};
  text-transform: capitalize;
  font-weight: 400;
  padding: 0.8rem 0;
  text-align: center;
  text-transform: capitalize;
  width: 20rem;
  border-radius: 0.8rem;
  transition: all 0.3s;
  &:hover {
    transform: translateY(-0.3rem);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  }
`;
export const Outline = styled(Link)`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.bgWhite};
  text-transform: capitalize;
  font-weight: 400;
  padding: 0.8rem 0;
  text-align: center;
  text-transform: capitalize;
  width: 20rem;
  border-radius: 0.8rem;
  transition: all 0.3s;
  border: 1px solid ${({ theme }) => theme.textDark};
  &:hover {
    transform: translateY(-0.3rem);
    background-color: ${({ theme }) => theme.bgBlueDark};
    color: ${({ theme }) => theme.textWhite};
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  }
`;
export const Text = styled(Link)`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.bgWhite};
  font-weight: 400;
  padding: 0.8rem 0;
  text-align: center;
  text-transform: capitalize;
  width: 22rem;
  transition: all 0.3s;
  border-bottom: 2px solid transparent;
  &:hover {
    border-color: ${({ theme }) => theme.textDark};
  }
`;
