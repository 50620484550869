import { ProjectType } from "../types";

export const projects: ProjectType[] = [
  {
    title: "Job-Alert Bot",
    description:
      "It's a newsletter style bot that send alerts of new vacncies available on the Mozambican market to the subscribed users via whatsapp",
    role: "Developer & Creator",
    link: "https://job-alert.kelven.dev",
    images: ["/assets/jobalert-1.jpg"],
  },
  {
    title: "African Man Fashion",
    description:
      "It's a style guide for all men to get inspired by African style and it's available on Google Play",
    role: "Developer & Creator",
    link: "https://africa-fashion.kelven.dev",
    images: [
      "/assets/fashion-1.jpg",
      "/assets/fashion-2.jpg",
      "/assets/fashion-3.jpg",
    ],
  },

  {
    title: "Bazaraqui",
    description:
      "It is a marketplace created as one of the UNDP initiatives to reduce physical contact within the markets of the city of Maputo, in this way sellers only catalog their products and buyers can purchase them without leaving their home",
    role: "Frontend Developer & UI Designer",
    link: "https://bazaraqui.co.mz",
    images: [
      "/assets/bazaraqui-1.jpg",
      "/assets/bazaraqui-2.jpg",
      "/assets/bazaraqui-3.jpg",
    ],
  },
  {
    title: "HIOTRACK",
    description:
      "Hiotrack is a Hiotech vehicle tracking service that reaches the Mozambican market with the distinction of being cheap and easy to use",
    role: "Frontend Developer & UI Designer",
    link: "https://hiotrack.netlify.app/",
    images: [
      "/assets/hiotrack-2.jpg",
      "/assets/hiotrack-2.jpg",
      "/assets/hiotrack-3.jpg",
    ],
  },
  {
    title: "Meu Vodacom (Concept)",
    description:
      "Conceptual application I developed showing how I could improve the vodacom application",
    role: "Creator",
    link: "https://youtu.be/bM1mtOw9ixg",
    images: ["/assets/vodacom-1.jpg", "/assets/vodacom-2.jpg"],
  },
  {
    title: "Deezer (Clone)",
    description:
      "Application made as a challenge, where I challenge myself to find out what are my strengths and weaknesses in mobile app development",
    role: "Creator",
    link: "https://youtu.be/CIPYvm22hhY",
    images: ["/assets/deezer-1.jpg", "/assets/deezer-2.jpg"],
  },
];
