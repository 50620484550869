import styled from "styled-components";
import { NavHashLink as Link } from "react-router-hash-link";
import { Button } from "../Buttons";
import { H3 } from "../Typography";
import { IoEllipsisVerticalOutline, IoCloseOutline } from "react-icons/io5";
import { useState } from "react";
import { IoMoon, IoSunny } from "react-icons/io5";
interface Props {
  theme: "light" | "dark" | string;
  themeToggler: () => void;
}
const NavContainer = styled.div`
  background-color: ${({ theme }) => theme.bgWhite};
  padding: 1rem 2rem;
  position: fixed;
  z-index: 69;
  top: 0;
  left: 0;
  width: 100%;
`;
const NavContent = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 2.5rem;
`;
const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;
const VlinksWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`;
const Element = styled.li`
  list-style-type: none;
`;
const Verticalinks = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 2rem;
  right: 1rem;
  background-color: ${({ theme }) => theme.bgWhite};
  padding: 2rem;
  width: 30rem;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.bgGreyLighter};
  border-radius: 0.8rem;
`;
const LinkNav = styled(Link)`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.textDark};
  font-weight: 400;
  padding: 0.8rem 1rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.3s;
  border-bottom: ${({ ishome }: { ishome?: true | false }) =>
      ishome ? "0px" : "2px"}
    solid transparent;
  margin-right: 2rem;
  @media only screen and (max-width: 750px) {
    margin-right: 0;
    margin-bottom: ${({ ishome }) => (!ishome ? "2rem" : 0)};
  }
  &.active {
    border-color: ${({ theme }) => theme.textDark};
  }
  &:hover {
    border-color: ${({ theme }) => theme.textDark};
  }
`;
const IconWrapper = styled.div`
  position: relative;
  z-index: 70;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.6rem;
  width: 3rem;
  height: 3rem;
  color: ${({ theme }) => theme.textDark};
  display: none;
  @media only screen and (max-width: 750px) {
    display: block;
  }
`;
const LeftSide = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;
`;
const Toggler = styled.button`
  background-color: transparent;
  margin-right: 1rem;
  border: none;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.textDark};
  cursor: pointer;
`;
const Navigation = ({ theme, themeToggler }: Props) => {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <NavContainer>
      <NavContent>
        <LeftSide>
          <LinkNav to="/" ishome>
            <H3>Kelven Rubalaine</H3>
          </LinkNav>
        </LeftSide>
        <NavLinks>
          <Toggler onClick={themeToggler}>
            {theme === "dark" ? <IoSunny /> : <IoMoon />}
          </Toggler>
          <Element>
            <LinkNav to="/projects">Projects</LinkNav>
          </Element>
          <Element>
            <LinkNav smooth to="/#skills">
              skills
            </LinkNav>
          </Element>
          <Element>
            <LinkNav smooth to="/#about-me">
              About me
            </LinkNav>
          </Element>
          <Button to="/contact">Hire Me</Button>
        </NavLinks>
        {isShowing ? (
          <>
            <VlinksWrapper onClick={() => setIsShowing(false)}></VlinksWrapper>

            <Verticalinks onClick={() => setIsShowing(false)}>
              <Toggler onClick={themeToggler}>
                {theme === "dark" ? <IoSunny /> : <IoMoon />}
              </Toggler>
              <LinkNav to="/projects">Projects</LinkNav>
              <LinkNav smooth to="/#skills">
                skills
              </LinkNav>
              <LinkNav smooth to="/#about-me">
                About me
              </LinkNav>
              <Button to="/contact">Hire Me</Button>
            </Verticalinks>
          </>
        ) : null}
        <IconWrapper onClick={() => setIsShowing(!isShowing)}>
          {isShowing ? <IoCloseOutline /> : <IoEllipsisVerticalOutline />}
        </IconWrapper>
      </NavContent>
    </NavContainer>
  );
};

export default Navigation;
