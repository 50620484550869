import React from "react";
import { Container, S2, S4, S8 } from "../Components/Wrappers";
import styled from "styled-components";
import { H1, H2, P1, P3 } from "../Components/Typography";
import { Button, Outline, Text } from "../Components/Buttons";
import Skill from "../Components/Skill";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { codingSkills, extraSkills, toolSkills } from "../global/skills";
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 8rem;
`;
const ShowCase = styled.img`
  width: 100%;
`;
const Skills = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgGreyLighter};
  padding: 2rem;
  width: 100%;
  border-radius: 2.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const AboutContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.bgBlueLight};
  padding: 2rem;
  width: 100%;
  border-radius: 2.5rem;
  justify-content: space-between;
  @media only screen and (max-width: 680px) {
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
  }
`;
const AboutCard = styled.div`
  background-color: ${({ theme }) => theme.bgWhite};
  padding: 2rem;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  @media only screen and (max-width: 680px) {
    margin-top: -8rem;
  }
`;

const Owner = styled.img`
  border-radius: 2rem;
  width: 35rem;
  @media only screen and (max-width: 680px) {
    margin-top: -14rem;
  }
`;
const Home = () => {
  return (
    <>
      <S4 />
      <S8 />
      <Container>
        <Section>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            duration={2}
          >
            <H1>
              mobile developer<br></br> web developer<br></br> ui designer
            </H1>

            <S2 />
            <Outline to="contact">Learn more</Outline>
          </AnimationOnScroll>
        </Section>
        <Section>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            duration={2}
          >
            <H2>Projects</H2>
            <S2 />
            <P1>
              I've been working with software development since 2020, so here
              you can see demonstrations of some projects I've been involved in
              during this period.
            </P1>
          </AnimationOnScroll>
          <S2 />
          <AnimationOnScroll
            animateIn="animate__bounceInLeft"
            animateOut="animate__bounceOutLeft"
            duration={2}
          >
            <ShowCase
              src="/assets/devices.png"
              alt="bazaraqui mobile and desktop"
            />
            <S2 />
          </AnimationOnScroll>
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            duration={2}
          >
            <Text to="/projects">see my other projects</Text>
          </AnimationOnScroll>
        </Section>
        <Section id="skills">
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            animateOut="animate__fadeOut"
            duration={2}
          >
            <H2>Skills</H2>
            <S2 />
            <P1>Some of my skills related to software development</P1>
          </AnimationOnScroll>
          <S2 />
          <Skills>
            <div>
              {codingSkills.map((el, index) => (
                <AnimationOnScroll
                  animateIn="animate__zoomInRight"
                  animateOut="animate__zoomOutRight"
                  delay={50 * index}
                  duration={0.5}
                  key={index}
                >
                  <Skill type="code">{el}</Skill>
                </AnimationOnScroll>
              ))}
            </div>
            <div>
              {toolSkills.map((el, index) => (
                <AnimationOnScroll
                  animateIn="animate__zoomInRight"
                  animateOut="animate__zoomOutRight"
                  delay={70 * index}
                  duration={0.5}
                  key={index}
                >
                  <Skill type="tool">{el}</Skill>
                </AnimationOnScroll>
              ))}
            </div>
            <div>
              {extraSkills.map((el, index) => (
                <AnimationOnScroll
                  animateIn="animate__zoomInRight"
                  animateOut="animate__zoomOutRight"
                  delay={90 * index}
                  duration={0.5}
                  key={index}
                >
                  <Skill type="extra">{el}</Skill>
                </AnimationOnScroll>
              ))}
            </div>
          </Skills>
        </Section>
        <Section id="about-me">
          {/* <H2>About Me</H2> */}
          <S2 />
          <AboutContainer>
            <Owner src="/assets/owner-3.png" />
            <AnimationOnScroll animateIn="animate__headShake" duration={1.5}>
              <AboutCard>
                <H2>I'm kelven Rubalaine</H2>
                <S2 />
                <P3>
                  Hello, I am a young software developer from Mozambique, I like
                  to create beautiful and user-friendly user interfaces, I can
                  build from web applications to mobile applications. I'm
                  currently open for work
                </P3>
                <S2 />
                <Button to="contact">Get in touch</Button>
              </AboutCard>
            </AnimationOnScroll>
          </AboutContainer>
        </Section>
      </Container>
    </>
  );
};

export default Home;
