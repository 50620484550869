export const codingSkills = [
    "React Native",
    "React Js",
    "NextJS",
    "Typescript",
    "Mysql",
    "PostgresSQL",
    "CSS",
    "NodeJS"
];
export const toolSkills = ["Git & Github", "Strapi", "Wordpress", "Linux Os", "Figma", "VsCode", "Googling"];
export const extraSkills = [
    "Web Development",
    "Mobile Development",
    "Playstore App Publishing",
    "Digital Occean deploy",
    "Github Actions",
    "SSH",
];