import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const PageWrapper = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const BIG = styled.h1`
  font-size: 20rem;
  line-height: 1;
  letter-spacing: 2px;
  font-weight: 900;
  color: ${({ theme }) => theme.textDesaturated};
`;
const Heading1 = styled.h1`
  font-size: 5.5rem;
  letter-spacing: 2px;
  font-weight: 900;
  color: ${({ theme }) => theme.textDark};
`;
const Heading3 = styled.h1`
  font-size: 1.8rem;
  /* letter-spacing: 2px; */
  font-weight: 500;
  color: ${({ theme }) => theme.textDark};
`;
const CustomLink = styled(Link)`
  display: inline-block;
  margin-right: 1rem;
  margin-top: 2rem;
  text-decoration: none;
  background-color: ${({ theme }) => theme.textDesaturated};
  padding: 0.8rem 2rem;
  border-radius: 1rem;
  color: ${({ theme }) => theme.textDark};
`;
const NotFound = () => {
  return (
    <PageWrapper>
      <BIG>404</BIG>
      <Heading1>Oops! This page does not exist</Heading1>
      <Heading3>Maybe you can find what you need here</Heading3>
      <Heading3>
        <CustomLink to="/projects">Home</CustomLink>
        <CustomLink to="/projects">Projects</CustomLink>
        <CustomLink to="/projects">About me</CustomLink>
      </Heading3>
    </PageWrapper>
  );
};

export default NotFound;
