import styled from "styled-components";
import { Container } from "../Wrappers";
import { H4, P3 } from "../Typography";
import { NavHashLink as Link } from "react-router-hash-link";
import { S2 } from "../Wrappers";
import { Outline } from "../Buttons";
import { IoLogoLinkedin, IoLogoGithub, IoLogoTwitter } from "react-icons/io5";
const Foot = styled.footer`
  display: flex;
  background-color: ${({ theme }) => theme.bgGreyLighter};
  margin-bottom: 8rem;
  padding: 2rem;
  width: 100%;
  border-radius: 2.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    align-items: center;
    padding: 3rem;
  }
`;
const Flex = styled.div`
  display: flex;
`;
const IconWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  color: ${({ theme }) => theme.textDark};
  font-size: 2.2rem;
  margin-right: 2rem;
  text-decoration: none;
`;
const FootContain = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 420px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 2rem;
  }
`;
const FootLink = styled(Link)`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.textDark};
  font-weight: 400;
  padding: 0.8rem 1rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.3s;
  border-bottom: ${({ home }: { home?: boolean }) => (home ? "0px" : "2px")}
    solid transparent;
  margin-bottom: 1rem;
  &.active {
    border-color: ${({ theme }) => theme.textDark};
  }
  &:hover {
    border-color: ${({ theme }) => theme.textDark};
  }
`;
const Footer = () => {
  return (
    <Container>
      <Foot>
        <FootContain>
          <P3>
            “If you are working on something that you really care about, you
            don’t have to be pushed. The vision pulls you.” – Steve Jobs
          </P3>
          <S2 />
          <P3>Find me on:</P3>
          <S2 />
          <Flex>
            <IconWrapper
              to={{ pathname: "https://www.linkedin.com/in/kelven-rubalaine/" }}
              target="_blank"
            >
              <IoLogoLinkedin />
            </IconWrapper>
            <IconWrapper
              to={{ pathname: "https://github.com/Rubalaine" }}
              target="_blank"
            >
              <IoLogoGithub />
            </IconWrapper>
            <IconWrapper
              to={{ pathname: "https://twitter.com/kelven55554701" }}
              target="_blank"
            >
              <IoLogoTwitter />
            </IconWrapper>
          </Flex>
        </FootContain>
        <FootContain>
          <H4>Quick Links</H4>
          <S2 />
          <FootLink to="/projects">Projects</FootLink>
          <FootLink to="/#skills">Skills</FootLink>
          <FootLink to="/#about-me">About Me</FootLink>
          <Outline to="contact">Get In touch</Outline>
        </FootContain>
      </Foot>
    </Container>
  );
};

export default Footer;
