import React from "react";
import styled from "styled-components";
import {
  IoCodeOutline,
  IoCogOutline,
  IoSyncOutline,
} from "react-icons/io5";
import { P2 } from "./Typography";
interface Props {
  type: "code" | "tool" | "extra";
  children: React.ReactNode;
}
const Elements = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 0.8rem;
  background-color: ${({ theme }) => theme.bgBlueLight};
  color: ${({ theme }) => theme.textDark};
  font-size: 2.2rem;
  margin-right: 2rem;
`;
const Skill = ({ type, children }: Props) => {
  return (
    <Elements>
      <IconWrapper>
        {type === "code" ? (
          <IoCodeOutline />
        ) : type === "tool" ? (
          <IoCogOutline />
        ) : (
          <IoSyncOutline />
        )}
      </IconWrapper>
      <P2>{children}</P2>
    </Elements>
  );
};

export default Skill;
