import React from "react";
import { Container } from "../Components/Wrappers";
import { S4, S8, S2 } from "../Components/Wrappers";
import { P3, H2, H3, Pred } from "../Components/Typography";
import styled from "styled-components";
import { useEffect } from "react";
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 8rem;
`;
const LNK = styled.a`
  font: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;
const Contact = () => {
  useEffect(() => {
    document.title = "Kelven Rubalaine | Contacts";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <S4 />
      <S8 />
      <Container>
        <Section>
          <H3>Hello</H3>
          <S2 />
          <H2>I'm Kelven Rubalaine</H2>
          <S2 />
          <P3>
            I'm a web developer, mobile developer, and UI designer. I've been
            working with software development since 2019, so at that time I was
            able to experience several challenges in personal projects as well
            as those of third parties, and in the development of these projects
            I had the privilege of meeting and working with people from
            different areas of expertise. I currently have in my{" "}
            <LNK href="https://github.com/Rubalaine" target="_blank">
              github
            </LNK>{" "}
            account more than 50 repositories that represent my projects and my
            dedication.
          </P3>
          <S4 />
          <H3>Businness Enquiries</H3>
          <S2 />
          <P3>
            Just send me an email, and we'll set up a meeting to help you
            improve your business
          </P3>
          <S2 />
          <Pred>
            <LNK href="mailto:kelvenrubalaine@outlook.com">
              kelvenrubalaine@outlook.com
            </LNK>
          </Pred>
          <S4 />
        </Section>
      </Container>
    </>
  );
};

export default Contact;
