import { useEffect } from "react";
import { Container, S2, S4, S8 } from "../Components/Wrappers";
import { H2, P1 } from "../Components/Typography";
import styled from "styled-components";
import Project from "../Components/Project";
import { projects } from "../global/projects";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 8rem;
`;

const Projects = () => {
  useEffect(() => {
    document.title = "Kelven Rubalaine | Projects";
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <S4 />
      <S8 />
      <Container>
        <Section>
          <H2>Projects</H2>
          <S2 />
          <P1>
            I've been working with software development since 2020, so here you
            can see demonstrations of some projects I've been involved in during
            this period.
          </P1>
        </Section>
        {projects.map((project, inddex) => (
          <Project key={inddex} project={project} />
        ))}
        <S8 />
      </Container>
    </>
  );
};

export default Projects;
