import styled from "styled-components";

export const P1 = styled.p`
  font-family: "Numans", sans-serif;
  font-size: 1.8rem;
  line-height: 2;
  color: ${({ theme }) => theme.textDark};
  font-weight: 400;
`;
export const P2 = styled.p`
  font-family: "Numans", sans-serif;
  font-size: 1.7rem;
  line-height: 2;
  color: ${({ theme }) => theme.textDark};
  font-weight: 400;
`;
export const P3 = styled.p`
  font-family: "Numans", sans-serif;
  font-size: 1.6rem;
  line-height: 2;
  color: ${({ theme }) => theme.textDark};
  font-weight: 400;
`;
export const Pred = styled.p`
  font-family: "Numans", sans-serif;
  font-size: 1.6rem;
  line-height: 2;
  color: ${({ theme }) => theme.textred};
  font-weight: 400;
`;

export const H1 = styled.h1`
  font-family: "Inter", sans-serif;
  font-size: 5rem;
  letter-spacing: 0.25rem;
  line-height: 2;
  font-weight: 900;
  color: ${({ theme }) => theme.textDark};
  text-transform: uppercase;
`;
export const H2 = styled.h2`
  font-family: "Inter", sans-serif;
  font-size: 2.8rem;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  font-weight: 900;
  color: ${({ theme }) => theme.textDark};
  text-transform: uppercase;
`;
export const H3 = styled.h3`
  font-family: "Inter", sans-serif;
  font-size: 2.2rem;
  letter-spacing: 0.1rem;
  line-height: 1.7;
  font-weight: 900;
  color: ${({ theme }) => theme.textDark};
  text-transform: capitalize;
`;
export const H4 = styled.h4`
  font-family: "Inter", sans-serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  line-height: 1.7;
  font-weight: 500;
  color: ${({ theme }) => theme.textDark};
  text-transform: capitalize;
`;
