import { ProjectType } from "../types";
import styled from "styled-components";
import { H4, P3, P2 } from "./Typography";
import { S2 } from "./Wrappers";
import { Outline } from "./Buttons";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

interface Props {
  project: ProjectType;
}
const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.bgWhite};
  display: flex;
  margin-bottom: 5rem;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
`;
const CRS = styled(Carousel)`
  width: 58%;
  font-size: 1.6rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;
const Info = styled.div`
  width: 40%;
  @media only screen and (max-width: 750px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 4rem;
  }
`;
const Project = ({ project }: Props) => {
  return (
    <Wrapper>
      <Info>
        <H4>{project.title}</H4>
        <S2 />
        <P3>{project.description}</P3>
        <S2 />
        <P2>My Role: {project.role}</P2>
        <S2 />
        <Outline
          to={{
            pathname: project.link,
          }}
          target="_blank"
        >
          See a Demo
        </Outline>
      </Info>
      <CRS
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        autoPlay
        infiniteLoop
        interval={5000}
      >
        {project.images.map((url, index) => (
          <ImageWrapper key={index}>
            <img src={url} alt={project.title + " image"} loading="lazy" />
          </ImageWrapper>
        ))}
      </CRS>
    </Wrapper>
  );
};

export default Project;
