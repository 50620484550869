import styled from "styled-components";
export const Container = styled.div`
  max-width: 1000px;
  padding: 0 2rem;
  margin: 0 auto;
`;
export const S2 = styled.div`
  width: 100%;
  content: " ";
  height: 2rem;
`;

export const S4 = styled.div`
  width: 100%;
  content: " ";
  height: 4rem;
`;
export const S6 = styled.div`
  width: 100%;
  content: " ";
  height: 6rem;
`;

export const S8 = styled.div`
  width: 100%;
  content: " ";
  height: 8rem;
`;
